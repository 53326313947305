import { Button, Segmented, Modal, message, Divider, Card, Select } from "antd";
import styled from "styled-components";
import historiesApi from "../../histories/histories.service";
import { useEffect, useState } from "react";
import {
  BaseModalProps,
  VehicleStatusControlType,
  VehicleStatusControlTypes,
  VehicleStatusToKorean,
} from "../../synapse/services/job-setting.interface";
import { TaskDefinitionDTO } from "../service/scooter.interface";
import { useLoading } from "../../common/fetch/useLoading";

import {
  getTaskDefinitions,
  postResetTaskDefinitionBulkTarget,
  postTaskDefinitionBulkTarget,
} from "../service/scooters.service";
import { SegmentedLabeledOption } from "antd/lib/segmented";
import { WarningOutlined } from "@ant-design/icons";
import ScootController from "../../../components/ScootController";
import { VehicleModelTypes } from "../../synapse/services/camp.interface";

interface SimpleVehicleDTO {
  vehicleId: string;
  imei: string;
  qr: string;
  smodel: VehicleModelTypes;
}

interface VehicleStatusSettingModalProps extends BaseModalProps {
  vehicles: SimpleVehicleDTO[];
}
type ZobaJobVehicleSettingType =
  | "VEHICLE_STATUS"
  | "TASK_STATUS"
  | "TASK_RESET";

export function VehicleStatusSettingModal(
  props: VehicleStatusSettingModalProps
) {
  const { close, fetch, visible, vehicles } = props;
  const isIncludeW1 = vehicles.some((scoot) => scoot.smodel === "W1");

  const [vehicleStatus, setVehicleStatus] =
    useState<VehicleStatusControlType>("WaitingForCapture");
  const [taskDefinitions, setTaskDefinitions] = useState<TaskDefinitionDTO[]>(
    []
  );
  const [selectedTaskDefinitionId, setSelectedTaskDefinitionId] = useState<
    number | null
  >(null);
  const { loading, setDone, setLoading } = useLoading();
  async function handleVehicleStatusSetting() {
    if (loading) return message.warning("로딩중...");
    try {
      setLoading();
      const result = await historiesApi.createHistories({
        type: vehicleStatus,
        imeis: vehicles.map((e) => e.imei),
        title: VehicleStatusToKorean[vehicleStatus],
      });
      console.log(result);
      if (result.status !== 200) throw result;
      message.success("설정성공");
    } catch (e) {
      console.error(e);
    } finally {
      setDone();
    }
  }
  async function handleTaskStatusSetting() {
    if (loading) return message.warning("로딩중...");
    if (!selectedTaskDefinitionId)
      return message.error("작업상태를 선택해주세요.");
    try {
      setLoading();
      const result = await postTaskDefinitionBulkTarget({
        vehicleIds: vehicles.map((e) => e.vehicleId),
        workDefinitionId: selectedTaskDefinitionId,
      });
      console.log(result);
      if (result.status !== 200) throw result;
      message.success("설정성공");
    } catch (e) {
      console.error(e);
    } finally {
      setDone();
    }
  }
  async function handleTaskStatusReset() {
    if (loading) return message.warning("로딩중...");
    try {
      setLoading();
      const result = await postResetTaskDefinitionBulkTarget({
        targetVehicleIds: vehicles.map((e) => e.vehicleId),
      });
      console.log(result);
      if (result.status !== 200) throw result;
      message.success("초기화성공");
    } catch (e) {
      console.error(e);
    } finally {
      setDone();
    }
  }

  const vehicleStatusOptions: SegmentedLabeledOption[] =
    VehicleStatusControlTypes.map((e) => ({
      label: VehicleStatusToKorean[e],
      value: e,
    }));
  const warning = (settingType: ZobaJobVehicleSettingType) => {
    Modal.confirm({
      title: "경고!",
      content: (
        <WarningModalContent>
          <p>
            선택된 모든 기기를 한번에 조작합니다.
            <br />
            모두 처리되는데 약 10분 정도 소요됩니다.
            <br /> 그래도 계속 진행하시겠습니까?
          </p>
        </WarningModalContent>
      ),
      icon: <WarningOutlined style={{ color: "#FF0000" }} />,
      okType: "danger",
      okText: "계속 진행",
      cancelText: "취소",
      onOk: onWarningOk(settingType),
    });
  };

  function onWarningOk(settingType: ZobaJobVehicleSettingType): () => void {
    return {
      VEHICLE_STATUS: handleVehicleStatusSetting,
      TASK_STATUS: handleTaskStatusSetting,
      TASK_RESET: handleTaskStatusReset,
    }[settingType];
  }

  const taskDefinitionOptions = taskDefinitions.map((e) => (
    <Select.Option key={e.id} value={e.id}>
      {e.displayName}
    </Select.Option>
  ));
  useEffect(() => {
    (async () => {
      const result = await getTaskDefinitions();
      if (!result) return;
      setTaskDefinitions(result);
    })();
  }, []);
  return (
    <Modal
      width={752}
      visible={visible}
      onCancel={() => {
        fetch();
        close();
      }}
      title={"일괄조작"}
      footer={false}
    >
      <ZobaJobVehicleSettingModalTitle>
        <p className={"title"}>{`${vehicles.length} 대 선택됨`}</p>
        <p className={"description"}>
          선택한 모든 기기를 한번에 조작합니다. 그래도 진행하시겠습니까?
        </p>
      </ZobaJobVehicleSettingModalTitle>
      <ScootController
        multipleImei={vehicles}
        imei={null}
        model={null}
        vehicleId={null}
        setForceReload={() => console.log("호출만 함")}
      />
      <Card
        title={"기기상태변경"}
        extra={
          <Button
            danger
            type={"primary"}
            onClick={() => warning("VEHICLE_STATUS")}
            disabled={loading}
          >
            설정
          </Button>
        }
      >
        <Segmented
          value={vehicleStatus}
          onChange={(e) => setVehicleStatus(e as VehicleStatusControlType)}
          options={
            isIncludeW1
              ? vehicleStatusOptions.filter(
                  (v) => v.value !== "WaitingForChangeBattery"
                )
              : vehicleStatusOptions
          }
        />
      </Card>
      <Card
        title={"작업상태변경"}
        extra={
          <>
            <Button
              danger
              type={"ghost"}
              onClick={() => warning("TASK_RESET")}
              disabled={loading}
              style={{ marginRight: "8px" }}
            >
              초기화
            </Button>
            <Button
              danger
              type={"primary"}
              onClick={() => warning("TASK_STATUS")}
              disabled={loading}
            >
              설정
            </Button>
          </>
        }
      >
        <Select
          style={{ width: "160px" }}
          placeholder={"상태를 선택해주세요"}
          showSearch
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
          onChange={(e) => setSelectedTaskDefinitionId(e)}
          value={selectedTaskDefinitionId}
          allowClear
          onClear={() => setSelectedTaskDefinitionId(null)}
        >
          {taskDefinitionOptions}
        </Select>
      </Card>
    </Modal>
  );
}
const ZobaJobVehicleSettingModalTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
  & > .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin: 0 0 8px;
  }
  & > .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 0 0 8px;
  }
`;
const WarningModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
`;
