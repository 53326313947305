import { removeEmptyValues } from "../../../utils/Params";
import { AdminApi } from "../../service";
import { RangeValue, TowClaim, TowRecordDTO, TowSearchDTO, TowStatus, TowType } from "./tow.interface";

export async function postTowed({ imei, body }: { imei: string, body: TowClaim }) {
  const result = await AdminApi.post(`vehicles/${imei}/towed`, body);

  if (result.status !== 200) return alert("에러발생");

  return result.data;
}

//GET IMPOSED DETAIL, 상세 들고와야지
export async function getTowDetail(id: string) {
  const result = await AdminApi.get(`towed/detail?id=${id}`);
  return result;
}

interface TowListRequestDTO {
  id?: number | null;
  imei?: string | null;
  qrcode?: string | null;
  bandIds?: string[] | null;
  types?: TowType[] | null;
  statuses?: TowStatus[] | null;
  occurredAtBefore?: number | null;
  occurredAtAfter?: number | null;
  registeredAtBefore?: number | null;
  registeredAtAfter?: number | null;
}

export async function getTowList(body: TowSearchDTO) {
  const { filter, ...pageParams } = body;

  const transformRangeValueToTimestamps = (range?: RangeValue) => {
    if (range && range[0] && range[1]) {
      return [ range[0].unix() * 1000, range[1].unix() * 1000 ];
    }
    return [ null, null ];
  };

  if(filter  == null) {
    const result = await AdminApi.post(`towed`, {
      ...pageParams
    });
    return result;
  } else {

    const occurredAtTs = transformRangeValueToTimestamps(filter.occurredAt);
    const registeredAtTs = transformRangeValueToTimestamps(filter.registeredAt);
  
    const values = {
      id: filter.id ? Number(filter.id) : undefined,
      imei: filter.imei,
      qrcode: filter.qrcode,
      bandIds: filter.bandIds,
      types: filter.types,
      statuses: filter.statuses,
      occurredAtBefore: occurredAtTs[0],
      occurredAtAfter: occurredAtTs[1],
      registeredAtBefore: registeredAtTs[0],
      registeredAtAfter: registeredAtTs[1]
    };
  
    const withoutEmptyValues: TowListRequestDTO = removeEmptyValues(values);
  
    const result = await AdminApi.post(`towed`, {
      ...pageParams,
      ...(removeEmptyValues(withoutEmptyValues) ? { filter: withoutEmptyValues } : {}),
    });
  
    return result;
  }

}

export async function getTowImages(qrcode: string){
  const result = await AdminApi.get(`complains/images?qrcode=${qrcode}`);
  return result;
}