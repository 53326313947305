import moment, { Moment } from "moment";
import { RideDto } from "../../rides/service/ride.interface";
import { TowClaim, TowRecordDTO } from "./tow.interface";
import { message } from "antd";
import { getAddress } from "../../../utils/address.utils";
import { TowRegistrationForm } from "../pages/TowRegister";


export function towRecordDateFilter(r: TowRecordDTO, selectedDate: Moment | null) {
  const complainAt = moment(r.complainAt)
  if (selectedDate == null) return true;
  return selectedDate.isAfter(complainAt);
}


export function rideDateFilter(r: RideDto, selectedDate: Moment | null) {
  const endAtKST = moment.utc(r.endAt).local();
  if (selectedDate == null) return true;
  return selectedDate.isAfter(endAtKST);
}

export const towClaimRequestKorean: Record<keyof TowRegistrationForm, string> = {
  towedFee: "견인비용",
  towedReason: "견인사유",
  towedAt: "견인시간",
  towedImageUrls: "견인이미지",
  receiptImageUrl: "청구영수증",
}

function validateTowRegistrationForm(form: TowRegistrationForm, imageRequired: boolean) {
  try {
    Object.keys(form).forEach((key) => {
      if(key === 'towedImageUrls' && !imageRequired) return;
      if (form[key] == null || form[key] === "" || (form[key] instanceof Array && form[key].length === 0)) {
        message.error(`${towClaimRequestKorean[key]} 누락되었습니다.`);
        throw new Error(`${key} is required`);
      }
    })
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function transferFormToRequest(
  form: TowRegistrationForm,
  imageRequired: boolean,
  selectedRide?: RideDto,
): Promise<TowClaim | null> {
  if (!validateTowRegistrationForm(form, imageRequired)) return null;
  if (selectedRide == null) return {
    towedFee: form.towedFee!,
    towedReason: form.towedReason,
    towedAt: form.towedAt!.valueOf(),
    towedImageUrls: form.towedImageUrls ?? [],
    receiptImageUrl: form.receiptImageUrl[0]!,
  }

  if (selectedRide.endLocation.scootLocation == null) return null;

  const endLocation = await getAddress({
    latitude: selectedRide.endLocation.scootLocation.latitude,
    longitude: selectedRide.endLocation.scootLocation.longitude
  })

  return {
    towedFee: form.towedFee!,
    towedReason: form.towedReason,
    towedAt: form.towedAt!.valueOf(),
    towedImageUrls: form.towedImageUrls,
    receiptImageUrl: form.receiptImageUrl[0]!,
    endLocation: endLocation?.jibunAddress ?? '-',
    userId: selectedRide.user,
    rideId: selectedRide.id,
  }
}