import { Alert, Button, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Space, Tooltip } from "antd";
import { PageHeader } from "../../../components/page-header/PageHeader";
import { useTowTarget } from "../hooks/useTowTarget";
import { TowTargetVehicleInfo } from "../components/TowTargetVehicleInfo";
import { TowRecordTable } from "../components/TowRecordTable";
import { useEffect, useState } from "react";
import { useTowRideList } from "../hooks/useTowRideList";
import { TowRideTable } from "../components/TowRideTable";
import { useLoading } from "../../common/fetch/useLoading";
import { rideDateFilter, towRecordDateFilter, transferFormToRequest } from "../services/tow.util";
import { TowImageUploader } from "../components/TowImageUploader";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { MAIN_BAND_ID, REGIST_TOWING_WITHOUT_IMAGE } from "../../../constants";
import { InfoCircleOutlined } from "@ant-design/icons";
import { RideDto } from "../../rides/service/ride.interface";
import { TowRecordDTO } from "../services/tow.interface";
import { postTowed } from "../services/tow.service";
import { LoadingBackground } from "../../../components/LoadingBackground";
import styled from "styled-components";

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 21 },
};

const dataFilterTypes = ['ALL', 'SLICE'] as const
type DataFilterType = typeof dataFilterTypes[number]

const selectLabel = {
  "ALL": "전체",
  "SLICE": "최근 3개"
}

export interface TowRegistrationForm {
  towedFee: number | null;
  towedReason: string;
  towedAt: moment.Moment | null;
  towedImageUrls: string[];
  receiptImageUrl: string[];
}
//TODO: 내일 여기 완성

export function TowRegister() {
  const { auth, hasPermission } = useAuthContext();
  const { targetVehicle, towRecords, triggerSearch, recordLoading, searchQr, setSearchQr, loading: vehicleLoading } = useTowTarget();
  const [towRegistration, setTowRegistration] = useState<TowRegistrationForm>({
    towedFee: null,
    towedReason: '',
    towedAt: null,
    towedImageUrls: [],
    receiptImageUrl: [],
  });
  const { loading, setDone, setLoading } = useLoading();
  const { rideList, rideListLoading } = useTowRideList(targetVehicle?.imei);

  //견인건 필터부분
  const [selectTowId, setSelectTowId] = useState<number[]>([])
  const filteredTowRecordList = towRecords.filter((r) => towRecordDateFilter(r, towRegistration.towedAt));
  const splicedTowRecordList = filteredTowRecordList.slice(0, 3);
  const selectedTow = towRecords.find((r) => r.id === selectTowId[0]);

  //라이드건 필터부분
  const [selectRideId, setSelectRideId] = useState<string[]>([])
  const [dataFilterType, setDataFilterType] = useState<DataFilterType>("SLICE");
  const filteredRideList = rideList.filter((r) => rideDateFilter(r, towRegistration.towedAt));
  const splicedRideList = filteredRideList.slice(0, 3);
  const selectedRide = rideList.find((r) => r.id === selectRideId[0]);

  //이미지 필수 여부 (견인 사진만)
  const imageRequired = REGIST_TOWING_WITHOUT_IMAGE.findIndex((bandId) => bandId === auth?.band.id) === -1;
  const isMainBand = auth?.band.id === MAIN_BAND_ID;
  const towRecordSelectVisible = towRecords.length > 0;

  const rideDataSource: Record<DataFilterType, RideDto[]> = {
    "ALL": filteredRideList,
    "SLICE": splicedRideList,
  }

  const towRecordDataSource: Record<DataFilterType, TowRecordDTO[]> = {
    "ALL": filteredTowRecordList,
    "SLICE": splicedTowRecordList,
  }

  const onChangeRegistration = (param: Partial<TowRegistrationForm>) => {
    setTowRegistration((prev) => ({ ...prev, ...param }));
  };

  function init() {
    setSelectRideId([]);
    setSelectTowId([]);
    setTowRegistration({
      towedFee: null,
      towedReason: '',
      towedAt: null,
      towedImageUrls: [],
      receiptImageUrl: [],
    });
  }

  async function onSubmit() {
    const transferedData = await transferFormToRequest(towRegistration, imageRequired, selectedRide);

    if (transferedData == null || targetVehicle == null) return;
    try {
      setLoading();
      const result = await postTowed({ imei: targetVehicle.imei, body: transferedData });
      if (!result) throw result;
      alert("견인건이 등록되었습니다.");
      init();
    } catch (e) {
      console.error(e);
      alert("견인건 등록에 실패했습니다.");
    } finally {
      setDone();
    }
  }

  useEffect(() => {
    if (selectedTow) {
      const towingImages = selectedTow.complainImages.map((image) => image);
      // const settedImages = [...new Set([...towRegistration.towedImageUrls, ...towingImages])];      
      onChangeRegistration({ towedImageUrls: towingImages });
    }
  }, [selectedTow])

  useEffect(() => {
    init();
  }, [targetVehicle])

  useEffect(() => {
    if (hasPermission('TowMenuVisible') === false) {
      alert('권한이 없습니다.');
      window.location.href = '/';
    }
  }, [])

  if (!auth || vehicleLoading) return <LoadingBackground />

  return (
    <Row style={{ padding: '16px' }}>
      <Col span={24}>
        <PageHeader title={'견인 등록'} />
      </Col>
      <Col span={24} style={{ paddingBottom: '16px' }}>
        {
          !targetVehicle
            ? <Alert
              type={'info'}
              showIcon
              message="견인된 기기를 검색해주세요."
              description="견인 기기의 QR로 검색해주세요." />
            : <Alert
              type={'warning'}
              showIcon
              message="입력하신 내용으로 견인건이 청구됩니다."
              {...isMainBand && { description: "견인영수증 내 견인시간과 가장 가까운 견인건과 라이드를 선택해주세요." }}
            />
        }
      </Col>
      <Col span={24} style={{ paddingBottom: '16px' }}>
        <Form {...layout}>
          <Form.Item label="기기검색">
            <Space wrap>
              <Input value={searchQr} placeholder={'QR로 검색'} onChange={(e) => setSearchQr(e.target.value.toUpperCase())} onPressEnter={triggerSearch} />
              <Button onClick={triggerSearch}>
                검색하기
              </Button>
            </Space>
          </Form.Item>
          <VehicleInfoCard label="견인 대상">
            <TowTargetVehicleInfo vehicle={targetVehicle} />
          </VehicleInfoCard>
          {targetVehicle && <>
            <Form.Item label="견인 일/시" >
              <Space wrap>
                <DatePicker
                  showTime
                  value={towRegistration.towedAt}
                  onChange={(e) => onChangeRegistration({ towedAt: e })} />
                {isMainBand && imageRequired &&
                  <Select
                    style={{ width: 120 }}
                    value={dataFilterType}
                    onChange={(e) => setDataFilterType(e)}
                    allowClear={false}>
                    {dataFilterTypes.map((t) => (
                      <Select.Option key={t} value={t}>{selectLabel[t]}</Select.Option>
                    ))}
                  </Select>
                }
                {isMainBand && imageRequired &&
                  <Tooltip title="기본으로는 3개의 라이드건만 노출되며, 필터 변경 시 견인 이전 라이드건이 노출됩니다.">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              </Space>
            </Form.Item>
            {
              towRegistration.towedAt && (
                <>
                  {towRecordSelectVisible && <Form.Item label="견인건 선택">
                    <TowRecordTable
                      dataSource={towRecordDataSource[dataFilterType]}
                      loading={recordLoading}
                      onSelectIds={setSelectTowId}
                      selectIds={selectTowId} />
                  </Form.Item>}
                  {isMainBand && <Form.Item label="라이드건 선택">
                    <TowRideTable
                      dataSource={rideDataSource[dataFilterType]}
                      loading={rideListLoading.loading}
                      onSelectIds={setSelectRideId}
                      selectIds={selectRideId} />
                  </Form.Item>}
                </>
              )
            }
            <Form.Item label="견인 사진">
              <TowImageUploader
                maxCount={4}
                title={'견인 사진'}
                imageURL={towRegistration.towedImageUrls}
                setImageURL={(e) => onChangeRegistration({ towedImageUrls: [...towRegistration.towedImageUrls, e] })}
                deleteItem={(e) => onChangeRegistration({ towedImageUrls: towRegistration.towedImageUrls.filter((url) => url !== e) })} />
            </Form.Item>
            <Form.Item label="견인 영수증">
              <TowImageUploader
                maxCount={1}
                title={'견인 영수증'}
                imageURL={towRegistration.receiptImageUrl}
                setImageURL={(e) => onChangeRegistration({ receiptImageUrl: [...towRegistration.receiptImageUrl, e] })}
                deleteItem={(e) => onChangeRegistration({ receiptImageUrl: towRegistration.receiptImageUrl.filter((url) => url !== e) })} />
            </Form.Item>
            <Form.Item label={'견인 금액'}>
              <InputNumber
                style={{ width: '100%' }}
                value={towRegistration.towedFee}
                onChange={(e) => onChangeRegistration({ towedFee: e })} />
            </Form.Item>
            <Form.Item label={'견인 사유'}>
              <Select value={towRegistration.towedReason} onChange={(e) => onChangeRegistration({ towedReason: e })}>
                {
                  [
                    '보도와 차도가 구분된 도로의 차도',
                    '횡단보도, 산책로 등',
                    '버스정류장, 택시 승강장',
                    '지하철역 진출입로 및 주변',
                    '자전거 도로',
                    '어린이, 노인, 장애인 보호구역',
                    '장애인 점자블록 위, 교통약자 엘리베이터 진입로 주변 등 보도 구조물의 기능을 저해하는 공간',
                  ].map((reason, i) => (
                    <Select.Option key={i} value={reason}>{reason}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </>}
          <Divider />
          <ConfirmSection>
            <Space wrap direction={'horizontal'} style={{ width: '100%', justifyContent: 'flex-end' }}>
              <Button loading={loading} onClick={onSubmit} danger disabled={!targetVehicle}>
                견인등록
              </Button>
              <Button onClick={init}>
                입력 폼 초기화
              </Button>
            </Space>
          </ConfirmSection>
        </Form>
      </Col>
    </Row >
  );
}

const ConfirmSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const VehicleInfoCard = styled(Form.Item)`
  @media screen and (max-width: 768px){
    display: none;
  }
`